<template>
  <div class="BuyBox">
    <MultiBuyDiscount
      :key="activeVariant.partNo"
      :active-variant="activeVariant"
      @change-campaign-type="changeCampaignType"
    />
    <!--    <TextInput-->
    <!--      v-model="squareMeter"-->
    <!--      class="w-full mb-12"-->
    <!--      type="text"-->
    <!--      :label="'Ange m²'"-->
    <!--    />-->
    <!--    <div v-if="validSquareMeter" class="type-sm mb-12">-->
    <!--      <p><span class="font-medium">Rekommenderat:</span> {{ recommendedQuantity }} st förpackningar</p>-->
    <!--      <small>Motsvarar {{ recommendedQuantity * squareMeterPerQuantity }} m² ({{ squareMeterPerQuantity }}² per förpackning)</small>-->
    <!--    </div>-->
    <!--    <small v-else-if="squareMeter && !validSquareMeter" class="text-warningDark">Ange kvadratmeter i siffror. Decimaltecken ska vara punkt (t.ex 12.5)</small>-->
    <div v-if="!activeVariant.productHasExpired" class="type-sm font-medium">
      <div
        v-if="design !== 'quickbuy'"
        :class="{ 'hidden': disabledQuantityBtns,
        }"
      >
        {{ $t('productPage.sizeSelector.quantityLabel') }}
      </div>
      <div v-if="!disabledQuantityBtns" class="flex justify-left gap-12 items-end mt-4 select-none">
        <button
          class="btn btn--secondary btn--minus !basis-44 !h-44 !w-44 shrink-0"
          :disabled="totalQuantity === 1"
          @click="totalQuantity = (totalQuantity - 1)"
        />
        <div
          class="basis-44 p-12 flex items-center justify-center"
        >
          {{ totalQuantity }}
        </div>
        <button
          class="btn btn--secondary btn--plus !basis-44 !h-44 !w-44 shrink-0"
          :disabled="totalQuantity + 1 > canBuyMoreAmount && !activeVariant.isOnDemandProduct"
          @click="totalQuantity = (totalQuantity + 1)"
        />
        <div class="basis-22" />
        <div
          v-for="bulkOption in [6, 12, 24]"
          :key="'bulk-option-' + bulkOption"
          class="basis-44 h-44 shrink-0"
        >
          <button
            class="btn btn--secondary !basis-44 !h-44 !w-44 shrink-0"
            :disabled="!activeVariant.isOnDemandProduct && canBuyMoreAmount < bulkOption"
            @click="totalQuantity = (totalQuantity = bulkOption)"
          >
            +{{ bulkOption }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="userStore.isLoggedIn">
      <div v-if="!activeVariant.isOnDemandProduct">
        <div v-if="canBuyMoreAmount === 0 && cartStore.getQuantityFromPartNo(activeVariant.partNo) > 0">
          {{ $t('stock.maxInCart') }}
        </div>
        <div v-else-if="totalQuantity >= canBuyMoreAmount && canBuyMoreAmount > 0" class="type-sm-medium mt-12">
          {{ $t('stock.maxCanAdd', { num: canBuyMoreAmount }) }}
        </div>
      </div>
    </div>
    <div class="flex items-center type-xs my-16">
      <StockStatus :variant="activeVariant" :product-item="productItem" />
    </div>

    <!-- * * * * notify when back in stock -->
    <div v-if="activeVariant && !activeVariant.canAddToCart && !activeVariant.productHasExpired && userStore.isLoggedIn">
      <div v-if="!stockAlertSuccess" class="relative h-48 mb-12">
        <TextInput
          v-model="outOfStockEmail"
          type="email"
          class="input h-48"
          :label="$t('productPage.stockAlertPlaceholder')"
          @keyup.enter="subscribeToStockUpdates"
        />
      </div>
      <p v-if="stockAlertError" class="text-criticalDark text-14 mb-12">
        {{ $t('productPage.stockAlertError') }}
      </p>
      <p v-if="stockAlertSuccess" class="text-lightest bg-success p-4 text-center text-14 mb-12">
        {{ stockAlertSuccessText ?? $t('productPage.stockAlertSuccess') }}
      </p>
    </div>

    <!-- * * * * expired product - show related -->
    <div v-if="activeVariant.productHasExpired">
      <div v-if="relatedList.length" class="type-sm-medium mb-12 uppercase">
        {{ $t('productPage.similarProducts.title') }}
        <ProductRelatedProductsList :list="relatedList" :show-buy-btns="false" />
      </div>
    </div>

    <!-- * * * * active product -->
    <div v-else class="flex">
      <button
        v-if="!userStore.isLoggedIn"
        class="btn btn--lg flex-1 w-full mb-24 btn--confirm"
        @click.prevent="showLogin"
      >
        {{ $t('priceInfo.loginForPriceInfo') }}
      </button>
      <!-- logged in but cant be added to cart -->
      <button
        v-else-if="!props.activeVariant.canAddToCart"
        class="btn btn--lg flex-1 w-full mb-24 btn--secondary"
        :disabled="!validNotifyMail"
        @click="subscribeToStockUpdates"
      >
        {{ $t('notify.openModalButton') }}
      </button>

      <Tooltip
        v-else-if="userStore.isFinanceUser"
        :show-on-hover="true"
        class="ml-8"
        :text="$t('priceInfo.financeUserTooltip')"
      >
        <button
          class="btn btn--lg flex-1 w-full mb-24 btn--confirm"
          :class="{
            'pointer-events-none': true,
          }"
          :disabled="true"
        >
          {{ addToCartLabel }}
          <span
            v-if="totalSum > 0"
            class="ml-8 text-12 normal-case"
          >
            {{ totalSum }} kr
          </span>
        </button>
      </Tooltip>

      <!-- * * * * actual buy button -->
      <button
        v-else
        class="btn btn--lg flex-1 w-full mb-24 btn--confirm"
        :class="{
          'loading': loading,
          'pointer-events-none': disabledBuyButton,
        }"
        :disabled="disabledBuyButton || (canBuyMoreAmount === 0 && !(activeVariant.isOnDemandProduct || activeVariant.isPreOrderProduct))"
        @click="addToCart"
      >
        {{ addToCartLabel }}
        <span
          v-if="totalSum > 0"
          class="ml-8 text-12 normal-case"
        >
          {{ totalSum.toFixed(2) }} kr
        </span>
      </button>

      <!-- * * * * favorite button -->
      <button
        v-if="userStore.canFavorizeProducts"
        class="w-48 h-48 px-12 ml-12 shrink-0 border hover:bg-lightest hover:border-darkest transition-all"
        :class="{
          'bg-lighter border-lighter' : !isFavorite,
          'bg-lightest border border-darkest' : isFavorite,
        }"
        @click="addOrDeleteFavorite"
      >
        <img
          :src="showStar ? '/icons/favorite-filled.svg' : '/icons/favorite.svg'"
          class="h-24 w-24"
          :class="{
            'favoriteAnimateIn': loadingToTrue,
          }"
        >
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ProductModel } from '~/models/product';
import { useCartStore } from '~/store/cart';
import { useUserStore } from '~/store/user';
import { useProductStore } from '~/store/product';
import { useUiStore } from '~/store/ui';
import StockStatus from '~/components/product/StockStatus.vue';
import ProductRelatedProductsList from '~/components/product-page/ProductRelatedProductsList.vue';
import ProductVariant, { ProductVariantModel } from '~/models/productVariant';
import useApiFetch from '~/composeables/useApiFetch';
import { NorceParametricCodes, NorceRelatedProductTypeCodes } from '~/constants/norceCodes';
import MultiBuyDiscount from '~/components/product-page/MultiBuyDiscount.vue';
import Tooltip from '~/components/body/Tooltip.vue';
import { CampaignItem } from '~/constants/types/multiDiscount';
import useMultiDiscount from '~/composeables/useMultiDiscount';
import TextInput from '~/components/form-elements/TextInput.vue';

const userStore = useUserStore();
const uiStore = useUiStore();
const cartStore = useCartStore();
const productStore = useProductStore();
const { $t } = useNuxtApp();
const totalQuantity = ref(1);
const relatedList = ref<ProductModel[]>([]);

interface Props {
  activeVariant: ProductVariantModel & { name: string },
  productItem: ProductModel,
  disabled?: boolean
  crossell?: boolean,
  design: 'standard' |'quickbuy',
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  crossell: false,
  design: 'standard',
});

const { percentageCampaigns, hasCampaign, giftCampaigns } = useMultiDiscount({ activeVariant: props.activeVariant });

const priceListLocked = ref(hasCampaign && !giftCampaigns.value.length ? true : false);

const disabledQuantityBtns = computed(() => {
  return (!userStore.isLoggedIn || props.activeVariant.isSoldOut) && !props.activeVariant.isOnDemandProduct;
});

const canBuyMoreAmount = computed(()=> {
  const cartQty = cartStore.getQuantityFromPartNo(props.activeVariant.partNo);
  const qty = props.activeVariant.stockValue - cartQty;

  if (props.activeVariant.hasFixedRecommendedQty && (props.activeVariant.recommendedQty && qty > props.activeVariant.recommendedQty)) {
    if ((cartQty + totalQuantity.value) > props.activeVariant.recommendedQty) {
      return 0;
    }
    return props.activeVariant.recommendedQty;
  }

  return qty;
});

const disabledBuyButton = computed(() => {
  if (userStore.isFinanceUser) {
    return true;
  }
  if (props.disabled) {
    return true;
  }
  if (props.productItem.productUnavailable) {
    return true;
  }
  if (props.activeVariant.isInStock || props.activeVariant.isPreOrderProduct || props.activeVariant.isOnDemandProduct || props.activeVariant.hasIncomingDate || props.activeVariant.isSoldOut) {
    return false;
  }
  return true;

});

const addToCartLabel = computed(() => {
  if (props.activeVariant.isPreOrderProduct) {
    return $t('productPage.sizeSelector.preOrder');
  } else if (props.activeVariant.isTempSoldOut) {
    return $t('productPage.sizeSelector.watchStockBtn');
  } else if (props.activeVariant.isOnDemandProduct || (props.activeVariant && props.activeVariant.canAddToCart) || props.activeVariant.hasIncomingDate) {
    return $t('productPage.sizeSelector.addToCartBtn');
  }
  return $t('productPage.sizeSelector.watchStockBtn');
});

const totalSum = computed((): number => {
  if (props.activeVariant.canAddToCart) {
    if (userStore.getPrice(props.activeVariant.partNo, props.crossell).status === 'ok') {
      const basePrice = priceListLocked.value
        ? Number(userStore.getPrice(props.activeVariant.partNo, props.crossell).price?.priceBeforeDiscountBeforeVat) ?? 0
        : Number(userStore.getPrice(props.activeVariant.partNo, props.crossell).price?.priceBeforeVat) ?? 0;

      if (percentageCampaigns.value.length > 0) {
        const highestLimitReachedTrue = percentageCampaigns.value
          .filter(item => totalQuantity.value >= item.limit)
          .reduce<CampaignItem | null>((acc, item) => {
            return !acc || item.limit > acc.limit ? item : acc;
          }, null);

        return highestLimitReachedTrue ? totalQuantity.value * basePrice * ((100 - highestLimitReachedTrue.gainedAmount) / 100) : totalQuantity.value * basePrice;
      } else {
        return totalQuantity.value * basePrice;
      }
    }
  }

  return 0;
});

const showLogin = () => {
  uiStore.setShowSigninModal(true, true);
};

const emit = defineEmits<{
  (e: 'changeSize', size: ProductVariant & { name: string }): void;
  (e: 'addToCart'): void;
  (e: 'addingToCart'): void;
}>();

const outOfStockEmail = userStore.isLoggedIn ? ref(userStore.userProfile.email) : ref('');

const { apiPost, lastResponseCode } = useApiFetch();
const loadingStockAlert = ref(false);
const stockAlertSuccess = ref(false);
const stockAlertSuccessText = ref(null);
const stockAlertError = ref(false);
const subscribeToStockUpdates = async() => {
  if (validNotifyMail.value) {
    loadingStockAlert.value = true;
    stockAlertError.value = false;
    stockAlertSuccess.value = false;
    stockAlertSuccessText.value = null;
    const res = await apiPost(`/products/${props.activeVariant.partNo}/stock-alerts`, {
      email: outOfStockEmail.value,
    });
    if (res) {
      stockAlertSuccess.value = true;
      if (lastResponseCode.value === 200) {
        stockAlertSuccessText.value = $t('productPage.stockAlertSuccessAgain');
      }

      setTimeout(()=> stockAlertSuccess.value = false, 3000);
    } else {
      stockAlertError.value = true;
    }

    loadingStockAlert.value = false;
  } else {
    console.warn('not valid');
  }
};

const validNotifyMail = computed(()=> {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return regex.test(outOfStockEmail.value);
});

const loading = ref(false);
const addToCart = async() => {
  loading.value = true;
  emit('addingToCart');

  if (props.activeVariant.canAddToCart || props.activeVariant.hasIncomingDate) {
    await cartStore.updateCart(
      props.activeVariant.partNo,
      totalQuantity.value,
      -1,
      true,
      props.activeVariant,
      false,
      props.productItem.objectID,
      priceListLocked.value
    );
    emit('addToCart');
  }

  loading.value = false;
};

const loadingToTrue = ref(false);
const loadingToFalse = ref(false);

const addOrDeleteFavorite = async() => {
  if (isFavorite.value) {
    loadingToFalse.value = true;
    await userStore.updateFavoriteProductQuantity(props.activeVariant.partNo, 0);
    loadingToFalse.value = false;
  } else {
    loadingToTrue.value = true;
    await userStore.saveFavoriteProduct(props.activeVariant.partNo, totalQuantity.value);
    loadingToTrue.value = false;
  }
};

const showStar = computed(()=> {
  return (isFavorite.value && !loadingToFalse.value) || loadingToTrue.value;
});

const isFavorite = computed((): boolean => {
  return !!userStore.userProfile.favoriteProducts?.find(favorite => favorite.id === props.activeVariant.partNo);
});

watch(
  () => props.activeVariant,
  () => {
    totalQuantity.value = 1;
    stockAlertError.value = false;
    stockAlertSuccess.value = false;
    if (props.activeVariant.productHasExpired) {
      setRelatedList();
    }
  }
);

onMounted(() => {
  if (props.activeVariant.productHasExpired) {
    setRelatedList();
  }
});

const setRelatedList = (async() => {
  const partNos = props.productItem.relatedProductsByType(NorceRelatedProductTypeCodes.Replacer);
  if (partNos.length > 0) {
    relatedList.value = await productStore.getRelatedProducts(partNos);
  }
});

const changeCampaignType = (type: boolean) => {
  if (!giftCampaigns.value.length) {
    priceListLocked.value = type;
  }
};

const squareMeter = ref('');
const squareMeterPerQuantity = ref<string>(props.activeVariant.squareMeterPerPackaging ?? 1.5);
const validSquareMeter = computed(() => {
  return squareMeter.value && squareMeter.value.match(/^[0-9]*\.?[0-9]*$/);
});
const recommendedQuantity = computed(() => {
  if (!validSquareMeter.value) {
    return 1;
  }
  const squareMeterPerQuantityFloat = parseFloat(squareMeterPerQuantity.value);
  const wanted = parseFloat(squareMeter.value.replace(',', '.'));
  let recommended = Math.round( wanted / squareMeterPerQuantityFloat);
  if (recommended * squareMeterPerQuantityFloat < wanted) {
    recommended += 1;
  }
  return recommended.toFixed(0);
});

watch(recommendedQuantity, (val) => {
  totalQuantity.value = parseInt(val, 10);
});
</script>

<style lang="postcss">

</style>
